<script setup lang="ts">
import { initFlowbite } from 'flowbite';
import { useStoryblokBridge, type StoryblokComponentType } from '@storyblok/vue';

const { params } = useRoute();
const { slug } = params;
const { locale } = useI18n();

const url = slug && Array.isArray(slug) && slug.length > 0 ? slug.join('/') : 'home';

const story = await useAsyncStoryblok(url.replace(/\/$/, ''), {
    version: useRoute().query._storyblok ? 'draft' : 'published',
    language: locale.value,
});

if (story.value.status) {
    throw createError({
        statusCode: story.value.status,
        statusMessage: story.value.response,
    });
}

const { meta } = await useDefaultMetatags(story, locale);

onMounted(() => {
    initFlowbite();
    if (story.value && story.value.id) {
        useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory));
    }
});

provide('story', story);

useServerSeoMeta(meta);
</script>

<template>
    <StoryblokComponent
        v-if="story"
        :blok="{ ...story.content, published_at: story.published_at, created_at: story.created_at }"
    />
</template>
